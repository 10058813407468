import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Sprint } from '../../api/sentinel';
import tokenService from '../../authentication/tokenService';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import Sprints from './Sprints';
import SprintGoal from './SprintGoal';
import theme from '../../theme/theme';

export default function NavigationPanel({
    selectedSprint,
    sprints,
    projectId,
    iterationGoalSucceeded,
}: {
    selectedSprint?: Sprint;
    sprints?: Sprint[];
    projectId: string;
    iterationGoalSucceeded?: 'Y' | 'N';
}) {
    const { t } = useTranslation();
    const translation = {
        btnText: t('navigationPanel_addSprintBtn'),
        sprintCommentary: t('navigationPanel_sprintCommentary'),
    };

    const linkToAdd = () => {
        const latestSprintId = sprints?.length
            ? sprints?.reduce(
                  (max, sprint) => Math.max(max, sprint.sprintId ?? 0),
                  0,
              )
            : '';

        return `/AddSprint/${projectId}/${latestSprintId}`;
    };

    return (
        <>
            <Box
                sx={{
                    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
                    borderTop: `solid 1px ${theme.palette.grey[300]}`,
                    backgroundColor: theme.palette.common.white,
                    margin: '0 0 1rem 0',
                }}
            >
                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{ padding: '0' }}
                >
                    <Grid container spacing={0}>
                        <Grid
                            item
                            lg={6}
                            xs={12}
                            sx={{
                                padding: {
                                    xl: '1rem 1rem 1rem 3%',
                                    xs: '1rem 3%',
                                },
                                borderRight: {
                                    lg: `solid 1px ${theme.palette.grey['300']}`,
                                    xs: 'none',
                                },
                                borderBottom: {
                                    lg: 'none',
                                    xs: `solid 1px ${theme.palette.grey['300']}`,
                                },
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={0}
                                sx={{
                                    alignItems: selectedSprint?.sprintId
                                        ? 'flex-end'
                                        : 'center',
                                }}
                            >
                                <Sprints
                                    projectId={projectId}
                                    sprints={sprints ?? []}
                                    sprintId={selectedSprint?.sprintId}
                                />
                                <ReadOnlyTooltip
                                    childDisabled={
                                        !tokenService.hasEditingRights()
                                    }
                                >
                                    <Button
                                        href={linkToAdd()}
                                        startIcon={<AddIcon />}
                                        size="small"
                                        color="primary"
                                        disabled={
                                            !tokenService.hasEditingRights()
                                        }
                                        data-testid="addSprintButton"
                                    >
                                        {translation.btnText}
                                    </Button>
                                </ReadOnlyTooltip>
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            xs={12}
                            sx={{
                                padding: {
                                    xl: '1rem 3% 1rem 1rem',
                                    xs: '1rem 3%',
                                },
                            }}
                        >
                            <Stack
                                sx={{ height: '100%' }}
                                direction="column"
                                justifyContent="center"
                            >
                                <SprintGoal
                                    message={selectedSprint?.sprintGoal}
                                    status={iterationGoalSucceeded}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
