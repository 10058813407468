import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';

function IconTooltip(props: {
    iconName: 'helper' | 'announcement';
    title?: string | undefined;
    id?: string | undefined;
    placement?:
        | 'top'
        | 'right'
        | 'bottom'
        | 'left'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined;
    maxWidth?: string | undefined;
}) {
    if (!props.title) return <></>;

    return (
        <>
            <Tooltip
                id={props.id}
                data-testid={props.id}
                placement={props.placement}
                title={
                    <Typography
                        component="p"
                        sx={{ whiteSpace: 'pre-line', fontSize: '12px' }}
                        mb="2"
                        mt="2"
                    >
                        {props.title}
                    </Typography>
                }
                componentsProps={{
                    tooltip: {
                        sx: {
                            maxWidth: props.maxWidth,
                            bgcolor: 'rgba(128, 128, 128, 1)',
                        },
                    },
                }}
            >
                <IconButton aria-label={props.title}>
                    {props.iconName == 'helper' ? (
                        <HelpOutlineIcon />
                    ) : (
                        <SpeakerNotesOutlinedIcon />
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
}

export default IconTooltip;
