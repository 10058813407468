import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import { labelBuilder } from './labelBuilder';
import { SelectProps } from '@mui/material/Select/Select';
import Stack from '@mui/material/Stack';
import IconTooltip from './IconTooltip';

export interface Props {
    label: string;
    value: string;
}

export type SelectInputProps = SelectProps & {
    label?: string;
    name: string;
    options: Props[];
    placeholder?: string;
    required?: boolean;
};

const SelectInput = (props: SelectInputProps & { tooltip_title?: string }) => {
    const { control } = useFormContext();

    const controller: UseControllerReturn = useController({
        name: props.name,
        control,
    });

    return (
        <Stack
            alignItems="baseline"
            direction="row"
            justifyContent="flex-start"
            spacing={0}
        >
            <FormControl
                fullWidth
                error={!!controller.fieldState.error}
                margin="normal"
                variant="standard"
            >
                <InputLabel id={`sentinel-select-label-${props.name}`}>
                    {labelBuilder(props.label as string, props.required)}
                </InputLabel>
                <Select
                    id={`sentinel-select-field-${props.name}`}
                    label={labelBuilder(props.label as string, props.required)}
                    labelId={`sentinel-select-label-${props.name}`}
                    placeholder={props.placeholder}
                    onChange={controller.field.onChange}
                    onBlur={controller.field.onBlur}
                    name={controller.field.name}
                    value={controller.field.value}
                    ref={controller.field.ref}
                    disabled={props.disabled}
                    data-testid={`sentinel-select-field-${props.name}`}
                >
                    {props.options.map((option: Props) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {!!controller.fieldState.error && (
                    <FormHelperText>
                        {controller.fieldState.error?.message}
                    </FormHelperText>
                )}
            </FormControl>
            <IconTooltip title={props.tooltip_title} iconName="helper" />
        </Stack>
    );
};

export default SelectInput;
