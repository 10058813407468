import { IUser, LocalizationHelper, PeoplePicker } from '@microsoft/mgt-react';
import { useController, useFormContext } from 'react-hook-form';
import { IDynamicPerson } from '@microsoft/mgt-components';
import { useTranslation } from 'react-i18next';
import IconTooltip from '../../common/formContextBoundControls/IconTooltip';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

const CustomPeoplePicker = ({
    name,
    tooltip_title,
}: {
    name: string;
    tooltip_title: string;
}) => {
    const { control } = useFormContext();
    const { field: peoplePickerField } = useController({ name, control });
    const { t: translate } = useTranslation();
    const selectedUserId: string | undefined = peoplePickerField?.value?.userId;

    const hasSelectedUserChanged = (
        event: CustomEvent<IDynamicPerson[]>,
        userId: string | undefined,
    ): boolean => {
        return (
            (event.detail?.length > 0 && event.detail[0].id !== userId) ||
            event.detail?.length === 0
        );
    };

    const handleOnChange = (event: CustomEvent<IDynamicPerson[]>) => {
        peoplePickerField.onChange(
            event.detail
                .map((value: IUser) => {
                    return {
                        userId: value.id,
                        upn: value.userPrincipalName,
                    };
                })
                ?.pop(),
        );
    };

    LocalizationHelper.strings = {
        _components: {
            'people-picker': {
                maxSelectionsPlaceHolder: '',
            },
        },
    };

    return (
        <>
            <InputLabel shrink>
                {translate(`projectForm_${name}_label`)}
            </InputLabel>
            <Grid container justifyContent="space-between">
                <Grid item xs={11}>
                    <PeoplePicker
                        id={`${name}_id`}
                        userType="user"
                        personCardInteraction="hover"
                        selectionMode="single"
                        defaultSelectedUserIds={
                            selectedUserId ? [selectedUserId] : []
                        }
                        selectionChanged={event =>
                            hasSelectedUserChanged(event, selectedUserId) &&
                            handleOnChange(event)
                        }
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconTooltip title={tooltip_title} iconName="helper" />
                </Grid>
            </Grid>
        </>
    );
};

export default CustomPeoplePicker;
