import Typography from '@mui/material/Typography';
import { InputLabel } from '@mui/material';
import theme from '../../theme/theme';
import OverflowTooltip from '../../common/OverflowTooltip';

const SprintGoal = ({
    message,
    status,
}: {
    message?: string;
    status?: 'Y' | 'N';
}) => {
    const getLabel = () => {
        const isGoalMet = status === 'Y';
        const isGoalNotMet = status === 'N' && message;

        const statusText = isGoalMet
            ? 'MET'
            : isGoalNotMet
              ? 'NOT MET'
              : 'NOT SET';
        const suffixStyle = {
            color: isGoalNotMet
                ? theme.intensity.orange
                : isGoalMet
                  ? theme.intensity.green
                  : undefined,
        };

        return (
            <>
                SPRINT GOAL (<b style={suffixStyle}>{statusText}</b>)
            </>
        );
    };

    return (
        <>
            <InputLabel size="normal" sx={{ fontSize: '12px' }}>
                {getLabel()}
            </InputLabel>
            {message && (
                <OverflowTooltip title={message}>
                    <Typography variant="body1">{message}</Typography>
                </OverflowTooltip>
            )}
        </>
    );
};
export default SprintGoal;
