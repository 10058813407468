import { EditNote, Pageview } from '@mui/icons-material';
import CreateIcon from '@mui/icons-material/Create';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Sprint } from '../../api/sentinel';
import tokenService from '../../authentication/tokenService';
import dateService from '../../services/dateService';
import CheckIcon from '@mui/icons-material/Check';
import { SprintSprintStatusEnum } from '../../api/sentinel/openapi/models';
import theme from '../../theme/theme';
import IconTooltip from '../../common/formContextBoundControls/IconTooltip';

const Sprints = ({
    projectId,
    sprints,
    sprintId,
}: {
    projectId: string;
    sprints: Sprint[];
    sprintId?: number;
}) => {
    const { t } = useTranslation();
    const translation = {
        btnViewText: t('sprint_viewBtn'),
        btnEditText: t('sprint_editBtn'),
        labelSprint: t('sprint_label'),
        selectedSprintLabel: t('sprint_selectedSprint_label'),
        noSprintText: t('sprint_noSprint_text'),
        draftLabel: t('sprint_draft_label'),
        completedLabel: t('sprint_completed_label'),
    };
    const navigate = useNavigate();
    const onChange = (e: SelectChangeEvent) => {
        navigate(`/dashboard/${projectId}/${e.target.value}`);
    };

    const getSelectedSprintLabel = () => {
        const selectedSprint = sprints.find(
            sprint => sprint.sprintId === sprintId,
        );

        const sprintStatus = selectedSprint?.sprintStatus?.toLowerCase();
        const suffix = sprintStatus && t(`sprint_${sprintStatus}_label`);
        const suffixStyle =
            sprintStatus === 'draft' ? { color: theme.intensity.red } : {};

        return (
            <>
                {translation.selectedSprintLabel}{' '}
                <b style={suffixStyle}>{suffix}</b>
            </>
        );
    };

    const sprintItemMapper = (sprint: Sprint) => {
        const {
            sprintStartDate,
            sprintEndDate,
            sprintId,
            sprintNo,
            name,
            sprintStatus,
        } = sprint;
        return (
            <MenuItem key={sprintId} value={sprintId}>
                <Tooltip arrow title={t(`sprint_${sprintStatus}_tooltip`)}>
                    {sprintStatus === SprintSprintStatusEnum.Completed ? (
                        <CheckIcon
                            fontSize={'small'}
                            style={{ verticalAlign: 'bottom' }}
                        />
                    ) : (
                        <EditNote
                            fontSize={'small'}
                            style={{ verticalAlign: 'bottom' }}
                        />
                    )}
                </Tooltip>
                {`[${sprintNo}] ${name && name.trim().length !== 0 ? name + ', ' : ''}${dateService.toMediumFormat(
                    sprintStartDate,
                )} - ${dateService.toMediumFormat(sprintEndDate)}`}
            </MenuItem>
        );
    };

    if (sprints.length === 0) {
        return <div>{translation.noSprintText}</div>;
    }

    const sprintCommentary = sprintId
        ? sprints.find(c => c.id == sprintId)?.commentary
        : '';
    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <FormControl variant="standard">
                        <InputLabel id="sprint-select-label">
                            {getSelectedSprintLabel()}
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="sprint-select-label"
                            id="sprint-select"
                            displayEmpty
                            value={String(sprintId)}
                            onChange={onChange}
                        >
                            {sprints.map(sprintItemMapper)}
                        </Select>
                    </FormControl>

                    <Box mb="-6px">
                        {sprintCommentary && (
                            <IconTooltip
                                title={sprintCommentary}
                                placement="right"
                                iconName="announcement"
                                maxWidth="800px"
                                id="sprintTooltip"
                            ></IconTooltip>
                        )}
                    </Box>
                </Stack>

                {sprintId && (
                    <Button
                        href={`/EditSprint/${projectId}/${sprintId}`}
                        startIcon={
                            !tokenService.hasEditingRights() ? (
                                <Pageview data-testid="edit-sprint-icon-pageview" />
                            ) : (
                                <CreateIcon data-testid="edit-sprint-icon-createIcon" />
                            )
                        }
                        size="small"
                        data-testid="viewSprintButton"
                    >
                        {tokenService.hasEditingRights()
                            ? translation.btnEditText
                            : translation.btnViewText}
                    </Button>
                )}
            </Stack>
        </>
    );
};
export default Sprints;
